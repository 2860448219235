<h1 class="scr-title" mat-dialog-title>
  {{ 'MEDIA.DUPLICATE.TITLE' | translate }}
</h1>
<mat-dialog-content class="media-duplicate-dialog__content">
  <form [formGroup]="duplicateForm">
    <mat-form-field>
      <mat-label>{{ 'MEDIA.NAME.LABEL' | translate }}</mat-label>
      <input matInput formControlName="name" [placeholder]="'MEDIA.NAME.PLACEHOLDER' | translate" required>
    </mat-form-field>
  </form>
  <label>{{ 'MEDIA.DUPLICATE.CHOOSE_CUSTOMER' | translate }}</label>
  <app-customer-recent-search-select *ngIf="selectedCustomer" [initialCustomer]="selectedCustomer"
    (selectedCustomerChange)="onSelectedCustomer($event)">
  </app-customer-recent-search-select>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="hcl-button" mat-button [mat-dialog-close]="true" type="button">
    {{ 'CANCEL' | translate }}
  </button>
  <button class="hcl-button-confirm" mat-button (click)="doDuplicate()"
    [disabled]="!selectedCustomer || duplicateForm.invalid">
    {{ 'DUPLICATE' | translate }}
  </button>
</mat-dialog-actions>