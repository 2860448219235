<div mat-dialog-title class="scr-title">
  {{ 'TEMPLATE.CREATE_DIALOG.TITLE' | translate }}
</div>
<mat-dialog-content class="template-create-dialog__content">
  <span class="template-create-dialog__content__subtitle">
    {{ 'TEMPLATE.CREATE_DIALOG.SUBTITLE' | translate }}
  </span>
  <button class="hcl-button-confirm" mat-button type="button" (click)="goToNewTemplate()"
    [innerHTML]="'TEMPLATE.CREATE_DIALOG.FREE' | translate">
  </button>
  <button class="hcl-button-confirm" mat-button type="button" (click)="chooseInheritingTemplate()"
    [innerHTML]="'TEMPLATE.CREATE_DIALOG.INHERITED' | translate">
  </button>
  <button *ngIf="canCreateLegacy" class="hcl-button" mat-button type="button" (click)="chooseLegacyTemplate()"
    [innerHTML]="'TEMPLATE.CREATE_DIALOG.LEGACY' | translate">
  </button>
  <button *cmnIfPlugin="['postermywall']" class="hcl-button-confirm" mat-button type="button"
    (click)="goToNewPosterMyWallTemplate()" [innerHTML]="'TEMPLATE.CREATE_DIALOG.POSTERMYWALL' | translate">
  </button>
  <button *cmnIfPlugin="['humeCanva']" class="hcl-button-confirm" mat-button type="button"
    (click)="goToHumeCanvaTemplateEditor()" [innerHTML]="'TEMPLATE.CREATE_DIALOG.HUMECANVA' | translate">
  </button>
</mat-dialog-content>