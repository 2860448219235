<h1 class="scr-title" mat-dialog-title>
  {{ 'TEMPLATE.LEGACY_DIALOG.TITLE' | translate }}
</h1>

<mat-dialog-content class="legacy-template-dialog__content">
  <div class="legacy-template-dialog__content__grid">
    <div *ngFor="let legacyTemplate of legacyTemplateDataSource.data"
      class="legacy-template-dialog__content__grid__template"
      [class.legacy-template-dialog--selected]="legacyTemplate.format === selectedTemplate"
      (click)="onSelect(legacyTemplate.format)">
      <div class="legacy-template-dialog__content__grid__template__preview">
        <div class="legacy-template-dialog__content__grid__template__preview__img" *ngIf="legacyTemplate.isLandscape">
          <img [src]="'/assets/images/screenlab/templates/thumbnails/' + legacyTemplate.format + '_horizontal.png'"
            onerror="this.style.display='none'">
        </div>
        <div class="legacy-template-dialog__content__grid__template__preview__img" *ngIf="legacyTemplate.isPortrait">
          <img [src]="'/assets/images/screenlab/templates/thumbnails/' + legacyTemplate.format + '_vertical.png'"
            onerror="this.style.display='none'">
        </div>
      </div>
      <div class="legacy-template-dialog__content__grid__template__name">
        {{ 'TEMPLATE.LEGACY.FORMAT.' + legacyTemplate.format | translate }}
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button type="button" class="hcl-button" mat-dialog-close>
    {{ 'CANCEL' | translate }}
  </button>
  <button mat-button class="hcl-button-confirm" [disabled]="selectedTemplate == null" (click)="doCreate()">
    {{ 'CREATE' | translate }}
  </button>
</mat-dialog-actions>