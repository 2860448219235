<h1 class="scr-title" mat-dialog-title>
  {{ 'MEDIA.IMPORT_MEDIAS' | translate }}
</h1>

<mat-dialog-content class="cmn-media-create-dialog__content">
  <div *ngIf="userHasLegacyDevice && uploadingDynamicTemplate" class="cmn-media-create-dialog__content__legacy-device">
    {{ 'DEVICE.WARNING.DEVICE_NOT_SUPPORTED' | translate : {type: 'DEVICE.TYPE.SCR_RASPBERRY_PI' | translate} }}
  </div>
  <div *ngIf="uploadError && uploadFinished" class="cmn-media-create-dialog__content__upload-error">
    {{ errorTranslationKey | translate }}
  </div>
  <div *ngIf="!uploadError && uploadFinished" class="cmn-media-create-dialog__content__upload-success">
    {{ 'MEDIA.IMPORT.SUCCESS' | translate }}
  </div>
  <dropzone [config]="dropzoneConfig" [message]="'MEDIA.DROPZONE.MESSAGE' | translate" (sending)="onSending($event)"
    (success)="onUploadSuccess($event)" (error)="onUploadError($event)">
  </dropzone>
  <p class="cmn-media-create-dialog__upload-constraint">
    {{ 'MEDIA.IMPORT.AUTHORIZED_FILES' | translate | addSpaceBeforeColon }}:
    {{ acceptedFileExtensionsStr }}
  </p>
  <p class="cmn-media-create-dialog__upload-constraint">
    {{ 'MEDIA.IMPORT.MAXIMUM_SIZE' | translate | addSpaceBeforeColon }}: {{ maxFileSizeInMo }}Mo
  </p>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="hcl-button-confirm" mat-button (click)="doClose()">
    {{ 'CLOSE' | translate }}
  </button>
</mat-dialog-actions>